import { extendTheme, theme as base } from '@chakra-ui/react';

const theme = extendTheme({
  fonts: {
    heading: `'Epilogue', ${base.fonts?.heading}`,
    body: `'Roboto', ${base.fonts?.body}`,
  },
  colors: {
    primary: '#463282',
    secondary: '#00FF00',
    gray: {
      50: '#fafafa',
      100: '#f0f0f0',
      200: '#eeeeee',
      300: '#e0e0e0',
      400: '#b9b9b9',
      500: '#9e9e9e',
      600: '#707070',
      700: '#616161',
      800: '#424242',
      900: '#212121',
    },
  },
  components: {
    Button: {
      variants: {
        inverted: {
          bg: 'white',
          color: 'primary',
          lineHeight: '24px',
          fontSize: '20px',
          fontWeight: '500',
          height: '44px',
          py: '0px',
          px: '20px',
          borderRadius: 0,
          borderColor: 'white',
          borderWidth: '2px',
          transition: '0.2s',
          _active: {
            px: '30px',
            bg: 'primary',
            color: 'white',
            borderColor: 'white',
            textDecoration: 'none',
          },
          _hover: {
            px: '30px',
            bg: 'primary',
            color: 'white',
            borderColor: 'white',
            textDecoration: 'none',
          },
          _visited: {
            textDecoration: 'none',
          },
        },
        solid: {
          bg: 'primary',
          color: 'white',
          lineHeight: '24px',
          fontSize: '20px',
          fontWeight: '500',
          height: '44px',
          py: '0px',
          px: '20px',
          borderRadius: 0,
          borderColor: 'primary',
          borderWidth: '2px',
          transition: '0.2s',
          _active: {
            px: '30px',
            bg: 'white',
            color: 'primary',
            borderColor: 'primary',
            textDecoration: 'none',
          },
          _hover: {
            px: '30px',
            bg: 'white',
            color: 'primary',
            borderColor: 'primary',
            textDecoration: 'none',
          },
          _visited: {
            textDecoration: 'none',
          },
          _loading: {
            px: '20px',
          },
        },
      },
    },
    Switch: {
      sizes: {
        xl: {
          thumb: {
            height: '8',
            width: '8',
            _checked: {
              transform: 'translateX(32px)',
            },
          },
          track: {
            height: '8',
            width: '16',
          },
        },
      },
      variants: {
        mac: {
          container: {},
          thumb: {
            bg: 'gray.200',
            _checked: {
              bg: 'primary',
            },
          },
          track: {
            padding: '0.5',
            bg: 'white',
            borderColor: 'gray.200',
            borderWidth: '1px',
            _checked: {
              borderColor: 'primary',
              bg: 'white',
            },
          },
        },
      },
    },
  },
  styles: {
    global: {
      body: {
        color: 'primary',
      },
      input: {
        outlineColor: 'primary',
      },
      textarea: {
        outlineColor: 'primary',
      },
      'input::placeholder': {
        color: 'primary',
        opacity: 1,
      },
      'textarea::placeholder': {
        color: 'primary',
        borderColor: 'primary',
        opacity: 1,
      },
      'button > .chakra-button__icon': {
        transition: '0.2s',
      },
      'button:hover > .chakra-button__icon': {
        paddingLeft: '6px',
      },
      'div > .chakra-button__icon': {
        transition: '0.2s',
      },
      'div:hover > .chakra-button__icon': {
        paddingLeft: '6px',
      },
      'div.icon-left > .chakra-button__icon': {
        paddingLeft: '-0.5rem',
        paddingRight: '0.2rem',
      },
      'div.icon-left:hover > .chakra-button__icon': {
        marginLeft: '-1.2rem',
        paddingRight: '0.6rem',
      },
      '.transitionAll': {
        transition: 'all 0.3s',
        transitionDelay: '0.1s',
      },
      '.chtml p:not(:last-child)': {
        marginBottom: '1em',
      },
      '.chtml ul:not(:last-child)': {
        marginBottom: '1em',
      },
      '.chtml ol:not(:last-child)': {},
      '.chtml ul': {
        marginLeft: '0.85em',
      },
      '.chtml ol': {
        marginLeft: '0.85em',
      },
      '.chtml a': { textDecoration: 'underline' },
    },
  },
});

export default theme;
