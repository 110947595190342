exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-career-overview-page-tsx": () => import("./../../../src/templates/careerOverviewPage.tsx" /* webpackChunkName: "component---src-templates-career-overview-page-tsx" */),
  "component---src-templates-career-page-tsx": () => import("./../../../src/templates/careerPage.tsx" /* webpackChunkName: "component---src-templates-career-page-tsx" */),
  "component---src-templates-default-page-tsx": () => import("./../../../src/templates/defaultPage.tsx" /* webpackChunkName: "component---src-templates-default-page-tsx" */),
  "component---src-templates-digital-page-tsx": () => import("./../../../src/templates/digitalPage.tsx" /* webpackChunkName: "component---src-templates-digital-page-tsx" */),
  "component---src-templates-home-page-tsx": () => import("./../../../src/templates/homePage.tsx" /* webpackChunkName: "component---src-templates-home-page-tsx" */),
  "component---src-templates-how-we-work-page-tsx": () => import("./../../../src/templates/howWeWorkPage.tsx" /* webpackChunkName: "component---src-templates-how-we-work-page-tsx" */),
  "component---src-templates-linktree-page-tsx": () => import("./../../../src/templates/linktreePage.tsx" /* webpackChunkName: "component---src-templates-linktree-page-tsx" */),
  "component---src-templates-news-overview-page-tsx": () => import("./../../../src/templates/newsOverviewPage.tsx" /* webpackChunkName: "component---src-templates-news-overview-page-tsx" */),
  "component---src-templates-news-page-tsx": () => import("./../../../src/templates/newsPage.tsx" /* webpackChunkName: "component---src-templates-news-page-tsx" */),
  "component---src-templates-project-page-tsx": () => import("./../../../src/templates/projectPage.tsx" /* webpackChunkName: "component---src-templates-project-page-tsx" */),
  "component---src-templates-projects-overview-page-tsx": () => import("./../../../src/templates/projectsOverviewPage.tsx" /* webpackChunkName: "component---src-templates-projects-overview-page-tsx" */),
  "component---src-templates-sustainability-page-tsx": () => import("./../../../src/templates/sustainabilityPage.tsx" /* webpackChunkName: "component---src-templates-sustainability-page-tsx" */)
}

